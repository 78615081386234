<nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
        <!--<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#menu"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon navbar-dark">
            </span>
        </button>-->
        <div class="container">
            <div class="row" style="margin-top: 1%;">
                <div class="col-5">
                    <p class="forevergreen">
                        #FOREVERGREEN</p>
                </div>
                <div class="col-3"><img class="imgLogoCabecera" src="../assets/imagenes/logomarketbetisblanco.svg">
                </div>
                <div class="col-4">
                    <p class="letrasMenuNav">
                        <a class="efectoSubrayado leftToRight" style="color: #fff; text-decoration: none;"
                            href="#seccionNoticias">NEWS</a>
                        <a class="efectoSubrayado leftToRight" style="color: #fff; text-decoration: none;"
                            href="#seccionRealbetisAcademyCamp">ACADEMIES</a>
                        <a class="efectoSubrayado leftToRight" style="color: #fff; text-decoration: none;"
                            href="#seccionRealbetisAcademyCamp">CAMP</a>
                        <a class="efectoSubrayado leftToRight" style="color: #fff; text-decoration: none;"
                            href="">PROGRAMS</a>
                        <a class="efectoSubrayado leftToRight" style="color: #fff; text-decoration: none;"
                            href="#formularioContacto">CONTACT</a>
                        <a id="idioma" class="efectoSubrayado leftToRight" style="color: #fff; text-decoration: none;"
                            href="/#home">ES<i class="flag-icon fi fi-es"></i></a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</nav>
<section id="cabecera">
    <div class="fade-in-image" style="height: 100%; width: 100%;">
        <img style="height: 100%; width: 100%;" src="../assets/imagenes/cabecerafoto.png">
        <img class="imgLetraRealBetisAcademy" src="../assets/imagenes/letrarealbetisacademynegra.svg">

    </div>
</section>
<section id="queEsRealBetisAcademy">
    <div class="imgQueEsRealBetisAcademy">
        <img data-aos="fade-right" data-aos-delay="2500" style="width: 100%; height: 100%;"
            src="../assets/imagenes/imgQueEsRealBetisAcademy.png">

    </div>
    <div class="textQueEsRealBetisAcademy">
        <p style="font-family: 'BetisRegular'; margin: 5% 0% 2% 10%;">HOME | REAL BETIS ACADEMY | <strong
                style="color: #008835;">¿WAHT'S IT?</strong></p>
        <h1 class="h1QueEsRealBetisAcademy" data-aos="fade-right" data-aos-delay="1500">
            about real betis<br> academy</h1>
        <p class="parrafoQueEsRealBetisAcademy">
            It is Real Betis Balompié's sports project that <br>
            aims to develop grassroots football, expanding the <br>
            values and the Betis brand around the world.
        </p>
        <p class="parrafoQueEsRealBetisAcademy">
            Through this project, Real Betis develops grassroots <br>
            football based on competitive merit. The club offers <br>
            its experience and technical support by implementing <br>
            its methodology through high quality training.
        </p>
        <a class="enlaceAcademias" href="#section_map">
            ACADEMIES
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-right"
                viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
            </svg>
        </a>
    </div>

</section>
<!--SECCION DE NOTICIAS-->
<section id="seccionNoticias">
    <div class="container" style="background-color: #008835; max-width: 100%;">
        <div id="myCarousel" class="carousel slide" data-ride="carousel">
            <!--Indicators-->
            <ol class="carousel-indicators">
                <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
                <li data-target="#myCarousel" data-slide-to="2"></li>
            </ol>

            <!--Wrapper for slides-->
            <div class="carousel-inner">
                <div class="item active">
                    <img class="d-block w-100" src="../assets/imagenes/dohuk.jpg" alt="Primer slide">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 style="font-family: 'BetisHeadlineDiezDiez'; color: #D79D29; font-size: 30px;">ÚLTIMAS
                            NOTICIAS</h5>
                        <a href="https://www.realbetisbalompie.es/noticias/escuela-futbol/el-real-betis-amplia-su-presencia-en-iraq-con-la-apertura-de-una-nueva-sede-en-dohuk-29116"
                            style="font-family: 'BetisHeadlineOchoOcho'; font-size: 57px; color: #fff;
                text-decoration: none; text-transform: uppercase;" target="_blank">
                            El Real Betis amplía su presencia en Iraq con la apertura
                            de una nueva sede en Dohuk</a>
                    </div>
                </div>

                <div class="item">
                    <img class="d-block w-100" src="https://thumb2.besoccerapps.com/rbetis/img_news/39/39813.jpg?v=1&size=1050x590t" alt="Primer slide">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 style="font-family: 'BetisHeadlineDiezDiez'; color: #D79D29; font-size: 30px;">ÚLTIMAS
                            NOTICIAS</h5>
                        <a href="https://www.realbetisbalompie.es/noticias/escuela-futbol/la-temporada-de-real-betis-academy-crecen-un-140-los-proyectos-deportivos-internacionales-del-club-v"
                            style="font-family: 'BetisHeadlineOchoOcho'; font-size: 57px; color: #fff;
                text-decoration: none; text-transform: uppercase;" target="_blank">
                            La temporada de Real Betis Academy: crecen un 140% los proyectos deportivos internacionales del club verdiblanco</a>
                    </div>
                </div>

                <div class="item">
                    <img class="d-block w-100" src="https://thumb2.besoccerapps.com/rbetis/img_news/39/39869.jpg?v=1&size=1050x590t" alt="Primer slide">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 style="font-family: 'BetisHeadlineDiezDiez'; color: #D79D29; font-size: 30px;">ÚLTIMAS
                            NOTICIAS</h5>
                        <a href="https://www.realbetisbalompie.es/noticias/escuela-futbol/el-real-betis-amplia-su-apuesta-por-el-futbol-japones-una-temporada-mas-29157"
                            style="font-family: 'BetisHeadlineOchoOcho'; font-size: 57px; color: #fff;
                text-decoration: none; text-transform: uppercase;" target="_blank">
                            El Real Betis amplía su apuesta por el fútbol japonés una temporada más</a>
                    </div>
                </div>

                <div class="item">
                    <img class="d-block w-100" src="https://thumb2.besoccerapps.com/rbetis/img_news/39/39991.jpg?v=1&size=1050x590t" alt="Primer slide">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 style="font-family: 'BetisHeadlineDiezDiez'; color: #D79D29; font-size: 30px;">ÚLTIMAS
                            NOTICIAS</h5>
                        <a href="https://www.realbetisbalompie.es/noticias/escuela-futbol/abre-el-plazo-de-inscripcion-de-la-escuela-betis-2023-24-29185"
                            style="font-family: 'BetisHeadlineOchoOcho'; font-size: 57px; color: #fff;
                text-decoration: none; text-transform: uppercase;" target="_blank">
                            Abre el plazo de inscripción de la Escuela Betis 2023-24</a>
                    </div>
                </div>

                <div class="item">
                    <img class="d-block w-100" src="https://thumb2.besoccerapps.com/rbetis/img_news/40/40080.jpg?v=1&size=1050x590t" alt="Primer slide">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 style="font-family: 'BetisHeadlineDiezDiez'; color: #D79D29; font-size: 30px;">ÚLTIMAS
                            NOTICIAS</h5>
                        <a href="https://www.realbetisbalompie.es/noticias/escuela-futbol/kuwait-nueva-sede-de-real-betis-academy-29225"
                            style="font-family: 'BetisHeadlineOchoOcho'; font-size: 57px; color: #fff;
                text-decoration: none; text-transform: uppercase;" target="_blank">
                            Kuwait, nueva sede de Real Betis Academy</a>
                    </div>
                </div>

                <div class="item">
                    <img class="d-block w-100" src="https://thumb2.besoccerapps.com/rbetis/img_news/40/40081.jpg?v=1&size=1050x590t" alt="Primer slide">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 style="font-family: 'BetisHeadlineDiezDiez'; color: #D79D29; font-size: 30px;">ÚLTIMAS
                            NOTICIAS</h5>
                        <a href="https://www.realbetisbalompie.es/noticias/escuela-futbol/real-betis-academy-abre-el-plazo-de-inscripcion-de-su-primera-escuela-de-futsal-29226"
                            style="font-family: 'BetisHeadlineOchoOcho'; font-size: 57px; color: #fff;
                text-decoration: none; text-transform: uppercase;" target="_blank">
                            Real Betis Academy abre el plazo de inscripción de su primera Escuela de Futsal</a>
                    </div>
                </div>

                <div class="item">
                    <img class="d-block w-100" src="https://thumb2.besoccerapps.com/rbetis/img_news/40/40676.jpg?v=1&size=1050x590t" alt="Primer slide">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 style="font-family: 'BetisHeadlineDiezDiez'; color: #D79D29; font-size: 30px;">ÚLTIMAS
                            NOTICIAS</h5>
                        <a href="https://www.realbetisbalompie.es/noticias/escuela-futbol/real-betis-academy-viaja-a-el-cairo-en-busqueda-de-jovenes-promesas-del-futbol-29332"
                            style="font-family: 'BetisHeadlineOchoOcho'; font-size: 57px; color: #fff;
                text-decoration: none; text-transform: uppercase;" target="_blank">
                            Real Betis Academy viaja a El Cairo en búsqueda de jóvenes promesas del fútbol</a>
                    </div>
                </div>

                <div class="item">
                    <img class="d-block w-100" src="../assets/imagenes/imgacademiaafrica.jpg" alt="Primer slide">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 style="font-family: 'BetisHeadlineDiezDiez'; color: #D79D29; font-size: 30px;">ÚLTIMAS
                            NOTICIAS</h5>
                        <a href="https://www.realbetisbalompie.es/noticias/escuela-futbol/el-real-betis-amplia-su-proyecto-en-africa-con-la-celebracion-de-un-campus-en-sudafrica-que-se-conve"
                            style="font-family: 'BetisHeadlineOchoOcho'; font-size: 57px; color: #fff;
                text-decoration: none; text-transform: uppercase;" target="_blank">
                            El Real Betis amplía su proyecto en África con la celebración de un campus en Sudáfrica que
                            se convertirá en una academia próximamente
                        </a>
                    </div>
                </div>

                <div class="item">
                    <img class="d-block w-100" src="../assets/imagenes/teamalliance.jpg" alt="Primer slide">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 style="font-family: 'BetisHeadlineDiezDiez'; color: #D79D29; font-size: 30px;">ÚLTIMAS
                            NOTICIAS</h5>
                        <a href="https://www.realbetisbalompie.es/noticias/escuela-futbol/el-real-betis-estrena-el-programa-team-alliance-recibiendo-al-inter-ohana-cf-de-hawai-28845"
                            style="font-family: 'BetisHeadlineOchoOcho'; font-size: 57px; color: #fff;
                text-decoration: none; text-transform: uppercase;" target="_blank">
                            El Real Betis estrena el programa Team Alliance recibiendo al Inter Ohana CF de Hawai
                        </a>
                    </div>
                </div>

                <div class="item">
                    <img class="d-block w-100" src="../assets/imagenes/img1noticias.png" alt="Primer slide">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 style="font-family: 'BetisHeadlineDiezDiez'; color: #D79D29; font-size: 30px;">ÚLTIMAS
                            NOTICIAS</h5>
                        <a href="https://www.realbetisbalompie.es/noticias/escuela-futbol/el-real-betis-recibe-a-diez-entrenadores-dentro-de-iraq-dentro-del-proyecto-coaches-development-prog"
                            style="font-family: 'BetisHeadlineOchoOcho'; font-size: 57px; color: #fff;
                text-decoration: none; text-transform: uppercase;" target="_blank">
                            EL REAL BETIS RECIBE A DIEZ ENTRENADORES DENTRO
                            DE IRAQ DENTRO DEL PROYECTO COACHES
                            DEVELOPMENT PROGRAMME</a>
                    </div>
                </div>

                <div class="item">
                    <img class="d-block w-100" src="../assets/imagenes/img2noticias.jpg" alt="Segundo slide">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 style="font-family: 'BetisHeadlineDiezDiez'; color: #D79D29; font-size: 30px;">ÚLTIMAS
                            NOTICIAS</h5>
                        <a href="https://www.realbetisbalompie.es/noticias/escuela-futbol/el-real-betis-crea-su-tercer-programa-de-campus-en-estados-unidos-con-real-betis-camp-rocky-mountain"
                            style="font-family: 'BetisHeadlineOchoOcho'; font-size: 57px; color: #fff;
                text-decoration: none; text-transform: uppercase;" target="_blank">
                            El Real Betis crea su tercer programa de campus
                            en Estados Unidos con Real Betis Camp
                            Rocky Mountains</a>
                    </div>
                </div>

                <div class="item">
                    <img class="d-block w-100" src="../assets/imagenes/img3noticias.jpg" alt="Tercer slide">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 style="font-family: 'BetisHeadlineDiezDiez'; color: #D79D29; font-size: 30px;">ÚLTIMAS
                            NOTICIAS</h5>
                        <a href="https://www.realbetisbalompie.es/noticias/escuela-futbol/ciudad-del-cabo-nueva-sede-de-real-betis-academy-en-sudafrica-28866"
                            style="font-family: 'BetisHeadlineOchoOcho'; font-size: 57px; color: #fff;
                text-decoration: none; text-transform: uppercase;" target="_blank">
                            Ciudad del Cabo, nueva sede de Real Betis
                            Academy en Sudáfrica
                        </a>
                    </div>
                </div>

                <div class="item">
                    <img class="d-block w-100" src="../assets/imagenes/img4noticias.jpg" alt="Cuarto slide">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 style="font-family: 'BetisHeadlineDiezDiez'; color: #D79D29; font-size: 30px;">ÚLTIMAS
                            NOTICIAS</h5>
                        <a href="https://www.realbetisbalompie.es/noticias/escuela-futbol/real-betis-academy-jordania-la-segunda-sede-del-club-verdiblanco-en-oriente-medio-28970"
                            style="font-family: 'BetisHeadlineOchoOcho'; font-size: 57px; color: #fff;
                text-decoration: none; text-transform: uppercase;" target="_blank">
                            Real Betis Academy Jordania, la segunda sede
                            del club verdiblanco en Oriente Medio
                        </a>
                    </div>
                </div>

                <div class="item">
                    <img class="d-block w-100" src="../assets/imagenes/img5noticias.jpg" alt="Quinto slide">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 style="font-family: 'BetisHeadlineDiezDiez'; color: #D79D29; font-size: 30px;">ÚLTIMAS
                            NOTICIAS</h5>
                        <a href="https://www.realbetisbalompie.es/noticias/escuela-futbol/el-real-betis-amplia-su-presencia-en-mexico-con-la-apertura-de-real-betis-academy-queretaro-28686"
                            style="font-family: 'BetisHeadlineOchoOcho'; font-size: 57px; color: #fff;
                text-decoration: none; text-transform: uppercase;" target="_blank">
                            El Real Betis amplía su presencia en México
                            con la apertura de Real Betis Academy Querétaro
                        </a>
                    </div>
                </div>

                <div class="item">
                    <img class="d-block w-100" src="../assets/imagenes/img6noticias.jpg" alt="Sexto slide">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 style="font-family: 'BetisHeadlineDiezDiez'; color: #D79D29; font-size: 30px;">ÚLTIMAS
                            NOTICIAS</h5>
                        <a href="https://www.realbetisbalompie.es/noticias/escuela-futbol/el-real-betis-llego-a-venezuela-para-quedarse-nace-real-betis-academy-caracas-28628"
                            style="font-family: 'BetisHeadlineOchoOcho'; font-size: 57px; color: #fff;
                text-decoration: none; text-transform: uppercase;" target="_blank">
                            El Real Betis llegó a Venezuela para quedarse:
                            nace Real Betis Academy Caracas
                        </a>
                    </div>
                </div>

                <div class="item">
                    <img class="d-block w-100" src="../assets/imagenes/img7noticias.jpg" alt="Septimo slide">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 style="font-family: 'BetisHeadlineDiezDiez'; color: #D79D29; font-size: 30px;">ÚLTIMAS
                            NOTICIAS</h5>
                        <a href="https://www.realbetisbalompie.es/noticias/escuela-futbol/el-real-betis-estrena-nuevo-proyecto-deportivo-en-oriente-medio-con-la-apertura-de-real-betis-camp-e"
                            style="font-family: 'BetisHeadlineOchoOcho'; font-size: 57px; color: #fff;
                text-decoration: none; text-transform: uppercase;" target="_blank">
                            El Real Betis estrena nuevo proyecto deportivo
                            en Oriente Medio con la apertura de Real Betis
                            Camp Egipto
                        </a>
                    </div>
                </div>

                <div class="item">
                    <img class="d-block w-100" src="../assets/imagenes/img8noticias.jpg" alt="Septimo slide">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 style="font-family: 'BetisHeadlineDiezDiez'; color: #D79D29; font-size: 30px;">ÚLTIMAS
                            NOTICIAS</h5>
                        <a href="https://www.realbetisbalompie.es/noticias/escuela-futbol/el-real-betis-estrena-su-tercer-proyecto-deportivo-internacional-en-estados-unidos-28514"
                            style="font-family: 'BetisHeadlineOchoOcho'; font-size: 57px; color: #fff;
                text-decoration: none; text-transform: uppercase;" target="_blank">
                            El Real Betis estrena su tercer proyecto
                            deportivo internacional en Estados Unidos
                        </a>
                    </div>
                </div>
            </div>
            <a class="left carousel-control" href="#myCarousel" data-slide="prev">
                <span class="glyphicon glyphicon-chevron-left"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="right carousel-control" href="#myCarousel" data-slide="next">
                <span class="glyphicon glyphicon-chevron-right"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>

</section>

<!--SECCION DE REAL BETIS ACADEMY Y REAL BETIS CAMP-->
<section id="seccionRealbetisAcademyCamp">
    <div class="caja1RealBetisAcademyCamp">
        <h1 class="h1RealBetisAcademyCamp">
            REAL BETIS ACADEMY</h1>
        <p class="parrafoRealBetisAcademyCamp">
            <strong>Real Betis Academy</strong> is the permanent <br>
            football program aimed at boys and girls from all <br>
            over the world. The objective of this project is to <br>
            export the experience of the Betis methodology to the <br>
            world for football players looking to add value to their <br>
            sporting career and enjoy a first class training methodology.
        </p>
        <p class="parrafoRealBetisAcademyCamp">
            This project expands the Real Betis brand and <br>
            values beyond our borders.
        </p>
    </div>
    <div class="caja2RealBetisAcademyCamp">
        <h1 class="h1RealBetisAcademyCamp">
            REAL BETIS CAMP</h1>
        <p class="parrafoRealBetisAcademyCamp">
            <strong>Real Betis Camps</strong> usually last 3 days.<br>
            They are a unique opportunity to be evaluated and trained <br>
            by a professional Real Betis coach who travels to the camp <br>
            to lead these training sessions.
        </p>
        <p class="parrafoRealBetisAcademyCamp">
            In these camps, a specific methodology is applied, <br>
            focused on individual technification and the improvement <br>
            of sporting performance.
        </p>
    </div>

</section>


<section id="section_map">
    <h5 class="h5SectionMap">
        OUR ACADEMIES AND CAMPS</h5>
    <agm-map style="background-color: green;" width="100%" class="mapa" [latitude]="lat" [longitude]="lng" [zoom]="zoom"
        [mapTypeId]="mapTypeId" [fitBounds]="true">
        <!--CLUSTER DE LAS ACADEMIAS-->
        <agm-marker-cluster [styles]="clusterStyles" zoomOnClick="true">
            <agm-marker (markerClick)="abrirVentana(academia)" *ngFor="let academia of Academias"
                [latitude]="academia.latitud" [longitude]="academia.longitud" [agmFitBounds]="true" [iconUrl]="
        {
          url: './assets/imagenes/logomarketbetisblanco.svg',
          scaledSize: {
            width: 80,
            height: 50
        }
      }">
            </agm-marker>
        </agm-marker-cluster>
        <!--CLUSTER DE LOS CAMPS-->
        <agm-marker-cluster [styles]="clusterStyles1" zoomOnClick="true">
            <agm-marker (markerClick)="abrirVentana1(camp)" *ngFor="let camp of Camps" [latitude]="camp.latitud"
                [longitude]="camp.longitud" [agmFitBounds]="true" [iconUrl]="
        {
          url: './assets/imagenes/logomarketbetiscampblanco.svg',
          scaledSize: {
            width: 80,
            height: 50
        }
      }">
            </agm-marker>
        </agm-marker-cluster>
        <!--CLUSTER DE LOS CAMPS REALIZADOS-->
        <agm-marker-cluster [styles]="clusterStyles2" zoomOnClick="true">
            <agm-marker (markerClick)="abrirVentana2(camp_finish)" *ngFor="let camp_finish of CampsFinish"
                [latitude]="camp_finish.latitud" [longitude]="camp_finish.longitud" [agmFitBounds]="true" [iconUrl]="
        {
          url: './assets/imagenes/logomarketbetiscampblanco.svg',
          scaledSize: {
            width: 80,
            height: 50
        }
      }">
            </agm-marker>
        </agm-marker-cluster>
    </agm-map>
</section>
<section style="background-color: #00782F; border: solid #00782F;">
    <div class="container" style="margin-top: 3%; margin-bottom: 3%;">
        <div class="row align-items-center">
            <div class="footer-trophies__trophy">
                <p class="counter">
                    26
                </p>
                <p class="counter1" data-aos="slide-up" data-aos-delay="500">
                    international projects</p>
            </div>
            <div class="footer-trophies__trophy">
                <p class="counter">4
                </p>
                <p class="counter1" data-aos="slide-up" data-aos-delay="500">
                    presence in four continents</p>
            </div>
            <div class="footer-trophies__trophy">
                <p class="counter">
                    12
                </p>
                <p class="p1" data-aos="slide-up" data-aos-delay="500">
                    academies</p>
            </div>
            <div class="footer-trophies__trophy">
                <p class="counter">
                    14
                </p>
                <p class="p1" data-aos="slide-up" data-aos-delay="500">
                    camps</p>
            </div>
            <div class="footer-trophies__trophy">
                <p class="counter">
                    3000</p>
                <p class="p1" data-aos="slide-up" data-aos-delay="500">
                    players</p>
            </div>
        </div>
    </div>
</section>

<section id="queEsRealBetisAcademy1">
    <div class="imgqueEsRealBetisAcademy">
        <img class="imagenQueEsRealBetisAcademy" data-aos="fade-down" data-aos-duration="3000" data-aos-delay="3000"
            src="../assets/imagenes/imgRealBetisInside.png">

    </div>
    <div class="parrafoQueEsRealBetisAcademy1">
        <h1 class="h1TitutloQueEsRealBetisAcademy1" data-aos="zoom-in-down" data-aos-delay="3000">
            real betis inside</h1>
        <p class="ParrafoQueEsRealBetisAcademy1">
            Through the <strong>Real Betis Inside</strong> experience, <br>
            players will have the opportunity to travel to Seville <br>
            to experience and get to know the Real Betis club from <br>
            the inside by training at its facilities. 
        </p>
        <p class="ParrafoQueEsRealBetisAcademy1">
            The players who enjoy this programme will be able to <br>
            train and feel like a Real Betis player. They will also <br>
            play friendly matches against teams from the city. <br>
            In addition, they will receive a certificate of the <br>
            programme and will be able to get to know and walk <br>
            around all the charms of such a unique city as <br>
            Seville and experience a match as one of the fans <br>
            in the stands of the Benito Villamarín Stadium and a <br>
            personalised tour of the stadium.
        </p>
    </div>

</section>

<section id="realBetisCoachesDevelopment">
    <div class="divRealBetisCoachesDevelopment">
        <h1 class="h1TitutloRealBetisCoachesDevelopment" data-aos="zoom-in-down" data-aos-delay="1800">
            REAL BETIS <br>
            COACHES <br>
            DEVELOPMENT</h1>
        <p class="parrafoRealBetisCoachesDevelopment">
            <strong style="color: #fff;">Real Betis Coaches Development</strong> is the international <br>
            project that focuses on offering technical training <br>
            under the Real Betis methodology to coaches from <br>
            all over the world. Within this programme, the coaches <br>
            receive theoretical and practical training given by <br>
            top level Real Betis coaches.
        </p>
        <p class="parrafoRealBetisCoachesDevelopment">
            During their stay in Seville, the coaches have the <br>
            opportunity to step on the grass of the Club's sports <br>
            facilities to put into practice the training received, <br>
            enjoying a VIP experience, living Real Betis from <br>
            the inside.
        </p>

    </div>
    <div class="divImagenRealBetisCoachesDevelopment">
        <img data-aos="zoom-out-right" data-aos-duration="3000" style="width: 100%; height: 100%;"
            src="../assets/imagenes/imgRealBetisCoachesDevelopment.png">
    </div>

</section>

<section id="realBetisTeamAlliance">
    <div class="imgRealBetisTeamAlliance">
        <img class="imagendivRealBetisTeamAlliance" data-aos="fade-down" data-aos-duration="3000"
            src="../assets/imagenes/imgRealBetisTeamAlliance.png">

    </div>
    <div class="cajaRealBetisTeamAlliance">
        <h1 class="h1TituloRealBetisTeamAlliance" data-aos="zoom-in-down" data-aos-delay="1800">
            REAL BETIS <br>
            TEAM ALLIANCE</h1>
        <p class="parrafoRealBetisTeamAlliance">
            This programme consists of an <strong>alliance with other <br>
                international clubs.</strong> The coaches will receive <br>
            monthly training sessions led by official Real Betis <br>
            coaches that will be taught through the Real Betis methodology.
        </p>
        <p class="parrafoRealBetisTeamAlliance">
            Through the <strong>Real Betis Team Alliance</strong>, the <br>
            players will be able to enjoy the Real Betis Inside <br>
            experience once a season. In addition, one of the Real Betis <br>
            coaches will make an annual visit to the facilities with <br>
            the aim of helping the development of the club.
        </p>
    </div>

</section>

<!--FORMULARIO DE REGISTRO-->
<section id="formularioContacto">
    <div class="fondoformulario">
        <div class="contenedorFormulario">
            <p class="textoformulario">CONTACT</p>
            <hr style="color: #7c7c7c;">
            <p style="text-align: center; font-family: 'BetisRegular'; font-size: 16px;"><strong>Leave us your data and
                    we
                    we will contact:</strong></p>
            <div class="formularioprofile">
                <form [formGroup]="saveFormulario" (ngSubmit)="onSubmit()" style="margin-left: 9%;
            margin-right: 7%;
            text-align: center;">
                    <input type="text" placeholder="Name" formControlName="nombre" class="form-control" />
                    <input type="text" placeholder="Surnames" formControlName="apellidos" class="form-control" />
                    <input type="email" placeholder="Email" formControlName="email" class="form-control" />
                    <input type="text" placeholder="Phone" formControlName="telefono" class="form-control" />
                    <input type="text" placeholder="Company" formControlName="empresa" class="form-control" />
                    <p style="text-align: center; font-family: 'BetisRegular'; font-size: 16px;"><strong>leave us your
                            comment:</strong></p>
                    <textarea style="text-align: center;" formControlName="detalles" class="form-control" rows="3"
                        placeholder="write your answer here.."></textarea>

                    <div class="form-group" style="text-align: center;">
                        <div class="row">
                            <div class="col-lg" style="margin-top: 5%; margin-bottom: 3%;">
                                <button id="btnactualizar" class="btn btn-success">SEND</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <!--<form
        class="formularioregistro"
        *ngIf="!isSuccessful"
        name="form"
        (ngSubmit)="f.form.valid && onSubmit()"
        #f="ngForm"
        novalidate
      >
        <div class="form-group">
          <input
                type="text"
                #nombre="ngModel"
                [(ngModel)]="form.nombre"
                placeholder="Nombre"
                name="nombre"
                minlength="3"
                class="form-control"
                required
                [ngClass]="{'is-valid': !nombre.invalid, 'is-invalid': nombre.invalid}"
                style="border-color: #008835; text-align: center;"
              />         
              <br>
        </div>
        <div class="form-group">
          <input
                type="text"
                #apellidos="ngModel"
                [(ngModel)]="form.apellidos"
                placeholder="Apellidos"
                name="apellidos"
                minlength="5"
                class="form-control"
                required
                [ngClass]="{'is-valid': !apellidos.invalid, 'is-invalid': apellidos.invalid}"
                style="border-color: #008835; text-align: center;"
              />
        </div><br>
        <div class="form-group">
          <input
                type="email"
                #email="ngModel"
                [(ngModel)]="form.email"
                placeholder="Email"
                name="email"
                class="form-control"
                required
                email
                [ngClass]="{'is-valid': !email.invalid, 'is-invalid': email.invalid}"
                pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                style="border-color: #008835; text-align: center;"
              />
              <div class="invalid-feedback" *ngIf="(email.dirty || email.touched)">
                <div *ngIf="email.errors && email.errors['pattern']" style="color: #008835; text-align: center; font-family: 'BetisSemiBold';">
                  * El correo electrónico no sigue el formato correcto. Sigue este ejemplo: voluntarios@realbetisbalompie.es
                </div>
              </div>
        </div><br>        
        <div class="form-group">
            <input
                  type="text"
                  (keypress)="keyPress($event)"
                  #movil="ngModel"
                  [(ngModel)]="form.movil"
                  placeholder="Teléfono móvil"
                  name="movil"
                  minlength="9"
                  maxlength="9"
                  class="form-control"
                  required
                  [ngClass]="{'is-valid': !movil.invalid, 'is-invalid': movil.invalid}"
                  style="border-color: #008835; text-align: center;"
                />        
                <br>
          </div>
          <p style="text-align: center; font-family: 'BetisRegular'; font-size: 12px;"><strong>Déjanos tu comentario:</strong></p>
        <div class="form-group">
            <textarea style="text-align: center;" formControlName="detalles" class="form-control"
            placeholder="Escribe aquí tu respuesta.."></textarea>
              <br>
        </div>
        <hr style="color: #7c7c7c;">
        <div class="form-group" style="text-align: center;">
          <div class="row">
            <div class="col-lg" style="margin-bottom: 3%;">
              <button (click)="registerButton()" id="btnenviar" class="btn btn-success">ENVIAR</button>
            </div>
          </div>
        </div>
      </form>-->
        </div>
    </div>

</section>

<section id="realBetisRedesSociales">
    <div class="container1">
        <div class="bloque-izquierdo-RRSS">
            <div class="titulo-rrss">
                <span class="span1">REAL BETIS EN REDES SOCIALES</span>
            </div>
            <div class="imagenes-rrss">
                <!--<div class="imagen-1-rrss imagen-rrss">
                    <a class="a" href="https://www.youtube.com/channel/UCeB7JZwcar2fVoK2w2f9OwA" target="_blank">
                        <img
                            src="https://image.mc.realbetisbalompie.es/lib/fe2f11737364047f771179/m/1/23e51a8c-b873-4812-a242-2b7c33b87aae.jpg">
                    </a>
                </div>-->
                <div class="imagen-2-rrss imagen-rrss">
                    <a class="a" href="https://twitter.com/RealBetis" target="_blank">
                        <img
                            src="https://image.mc.realbetisbalompie.es/lib/fe2f11737364047f771179/m/1/84a576b7-1fe1-41b3-8fcf-67ffaa197543.jpg">
                    </a>
                </div>
                <!--<div class="imagen-3-rrss imagen-rrss">
                    <a class="a" href="https://www.tiktok.com/@realbetisbalompie?lang=es" target="_blank">
                        <img
                            src="https://image.mc.realbetisbalompie.es/lib/fe2f11737364047f771179/m/1/a7225cfe-0ed9-415d-996e-1d73c71a1930.jpg">
                    </a>
                </div>-->
                <div class="imagen-4-rrss imagen-rrss">
                    <a class="a" href="https://www.instagram.com/realbetisbalompie/?hl=es" target="_blank"><img
                            src="https://image.mc.realbetisbalompie.es/lib/fe2f11737364047f771179/m/1/381de61b-7045-429b-aa37-87c3d238c62c.jpg"></a>
                </div>
                <div class="imagen-5-rrss imagen-rrss">
                    <a class="a" href="https://www.facebook.com/realbetisbalompie/" target="_blank"><img
                            src="https://image.mc.realbetisbalompie.es/lib/fe2f11737364047f771179/m/1/68f3dc24-8e01-4f8a-8386-6bfa613372f2.jpg"></a>
                </div>
                <div class="imagen-6-rrss imagen-rrss">
                    <a class="a" href="https://es.linkedin.com/company/real-betis-balompie" target="_blank"><img
                            src="https://image.mc.realbetisbalompie.es/lib/fe2f11737364047f771179/m/1/5a785060-aa25-4cff-b051-a9afd5b54bad.jpg"></a>
                </div>
            </div>
        </div>
        <div class="bloque-derecho-RRSS">
            <div class="titulo">
                <span class="span1">ACCIONES POR UN DEPORTE MÁS VERDE</span>
            </div>
            <div class="texto-grande">
                <span>#FOREVERGREEN</span>
            </div>
            <div class="texto-enlace">
                <a href="https://forevergreen.es/" target="_blank">
                    <span class="span2">QUIERO SER VERDE</span>
                    <img class="imgflecha"
                        src="https://image.mc.realbetisbalompie.es/lib/fe2f11737364047f771179/m/1/75253ab6-d7ec-4d06-bb8b-85732a16fd5f.png">
                </a>
            </div>
        </div>

    </div>

</section>

<section id="footer">
    <div class="left">
        <p class="marca"><b>© REAL BETIS BALOMPIÉ.</b></p>
        <p class="copyright">&nbsp;Esta página web es la única oficial del Real Betis Balompié. Todos los derechos
            reservados.</p>
    </div>
    <div class="right">
        <div class="containerr">
            <a href="https://www.realbetisbalompie.es/legal" target="_blank" class="texto">Avisos Legales |</a>


            <a href="https://www.realbetisbalompie.es/privacidad" target="_blank" class="texto">&nbsp;Política de
                privacidad |</a>


            <a href="https://www.realbetisbalompie.es/cookies" target="_blank" class="texto">&nbsp;Cookies |</a>


            <a href="https://www.realbetisbalompie.es/accesibilidad" target="_blank"
                class="texto">&nbsp;Accesibilidad</a>

        </div>
    </div>

</section>