import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

//const AUTH_API = '/api/auth/';
//const AUTH_API = 'https://192.168.2.193:8443/api/';
const AUTH_API = 'https://partners.realbetisbalompie.es:8443/api/';
const token: string = window.sessionStorage.getItem('auth-token');
const username = 'bet_2022_emp';
const password = 'gMDk6MzA6MDApamtSyzem'

const httpOptions = {
  headers: new HttpHeaders({ 
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa(username + ':' + password)
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  saveFormulario(nombre: string, apellidos: string, email: string, telefono: string, empresa: string, detalles: string): Observable<any>{
      return this.http.post(
        AUTH_API + 'registro_escuela',
        {
          nombre,
          apellidos,
          email,
          telefono,
          empresa,
          detalles
        },
        httpOptions
      );
  }

  private handleError(error: HttpErrorResponse){
    if (error.status == 0) {
      console.error('An error occurred:', error.error);
    }else{
      console.error('Backend returned code ${error.status}, body was: ', error.error);
    }
    return throwError('Somenthing bad happened: please try again later');
  }
}
