import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnglishComponent } from './english/english.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  { path: 'home', 
    component: HomeComponent},
  {
    path: 'english',
    component: EnglishComponent
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' }
  //Wild Card Route for 404 request
  //{ path: '**', pathMatch: 'full', 
  //component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
