import { Component, OnInit } from '@angular/core';
import { ClusterStyle } from '@agm/js-marker-clusterer/services/google-clusterer-types';
import { ActivatedRoute, ParamMap } from '@angular/router';
import  academias  from 'src/assets/json/adademias.json';
import camps from 'src/assets/json/camps.json';
import camps_finish from 'src/assets/json/camps_finish.json';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-english',
  templateUrl: './english.component.html',
  styleUrls: ['./english.component.css']
})
export class EnglishComponent implements OnInit {title = 'LEscuelasOfficial';
//cluster de las academias
clusterStyles: ClusterStyle[];
public idAcademia;
public idAcademiaImage;
public idAcademiaTitulo;
public idAcademiaTelefono;
public idAcademiaContacto;
public idAcademiaEmail;
mostrarAcademia = false;

//cluster de los camps
clusterStyles1: ClusterStyle[];
public idCamp;
public idCampImage;
public idCampTitulo;
public idCampTelefono;
public idCampContacto;
public idCampEmail;
mostrarCamp = false;


//cluster de los camps realizados
clusterStyles2: ClusterStyle[];
public idCampFinish;
public idCampFinishTitulo;
public idCampFinishFecha;
mostrarCampFinish = false;

saveFormulario!: FormGroup;
submitted = false;



constructor(private authService: AuthService, private formBuilder: FormBuilder ,private route: ActivatedRoute){
  this.lat = 40;
  this.lng = -3;
  this.zoom = 6; 
  this.mapTypeId = 'satellite';
  this.located = false;
}

ngOnInit(): void {
  this.saveFormulario = this.formBuilder.group({
    nombre: ['', Validators.required],
    apellidos: ['', Validators.required],
    email: ['', Validators.required],
    telefono: ['', Validators.required],
    empresa: ['', Validators.required],
    detalles: ['']

  })
  //PARA DARLE ESTILO A LOS CLUSTER ACADEMIAS DE LOS MARCADORES GRUPALES
  this.clusterStyles = [
    {
        textColor: "#FFFFFF",
        url: "assets/imagenes/logoMarker.png",
        height: 50,
        width: 55
    },
  ]
  //PARA DARLE ESTILO A LOS CLUSTER CAMPS DE LOS MARCADORES GRUPALES
  this.clusterStyles1 = [
    {
        textColor: "#FFFFFF",
        url: "assets/imagenes/logoMarker.png",
        height: 50,
        width: 55
    },
  ]
  //PARA DARLE ESTILO A LOS CLUSTER CAMPS FINALIZADOS DE LOS MARCADORES GRUPALES
  this.clusterStyles2 = [
    {
        textColor: "#FFFFFF",
        url: "assets/imagenes/logoMarker.png",
        height: 50,
        width: 55
    },
  ]
}

//VARIABLE QUE RECOJE LA CREACION ED LAS ACADEMIAS QUE TENEMOS EN EL JSON CREADO
Academias: any = academias;
//VARIABLE QUE RECOJE LA CREACION ED LAS CAMPS QUE TENEMOS EN EL JSON CREADO
Camps: any = camps;
//VARIABLE QUE RECOJE LA CREACION ED LAS CAMPS QUE TENEMOS EN EL JSON CREADO
CampsFinish: any = camps_finish;

lat: number;
lng: number;
zoom: number;
mapTypeId: String;
located: boolean;



getCurrentPosition(){
  navigator.geolocation.getCurrentPosition(position => {
    this.lat = position.coords.latitude;
    this.lng = position.coords.longitude;
    this.zoom = 8;
    this.located = true;
  })
}

abrirVentana(academia){
  console.log(JSON.stringify(academia.id))
  console.log(JSON.stringify(this.Academias))
  this.mostrarAcademia = true;
  Swal.fire({
    position: 'top-end',
    html: '<div><img style="width:100%" src='+academia.image+'><hr><h2 class="tituloAcademia">'+academia.titulo+'</h2><hr><h3 class="infoContactoAcademia">Contacto</h3><p class="infoAcademiaContactoJson"> '+academia.contacto+'</p><h3 class="tituloTelefono">Teléfono </h3><p class="infoAcademiaTelefonoJson">'+academia.telefono+'</p><h3 class="tituloEmail">Email</h3><p class="infoAcademiaEmailJson">'+academia.email+'<hr></div>',
    showConfirmButton: false,
    showCancelButton: false,
    cancelButtonText: 'IR A LA PÁGINA',
    cancelButtonColor: '#008835',
  })
  this.idAcademia = academia.id;
  this.idAcademiaImage = academia.image;
  this.idAcademiaTitulo = academia.titulo;
  this.idAcademiaTelefono = academia.telefono;
  this.idAcademiaContacto = academia.contacto;
  this.idAcademiaEmail = academia.email;
  /*this.route.paramMap.subscribe((params: ParamMap) =>{
    this.idAcademia = params.get(academia.id);
    console.log('ID ACADEMIA' + this.idAcademia);
  })*/
}
abrirVentana1(camp){
  console.log(JSON.stringify(camp.id))
  console.log(JSON.stringify(this.Camps))
  this.mostrarCamp = true;
  Swal.fire({
    position: 'top-end',
    html: '<div><img style="width:100%" src='+camp.image+'><hr><h2 class="tituloAcademia">'+camp.titulo+'</h2><hr><h3 class="infoContactoAcademia">Contacto</h3><p class="infoAcademiaContactoJson"> '+camp.contacto+'</p><h3 class="tituloTelefono">Teléfono </h3><p class="infoAcademiaTelefonoJson">'+camp.telefono+'</p><h3 class="tituloEmail">Email</h3><p class="infoAcademiaEmailJson">'+camp.email+'<hr></div>',
    showConfirmButton: false,
    showCancelButton: false,
    cancelButtonText: 'IR A LA PÁGINA',
    cancelButtonColor: '#008835',
  })
  this.idCamp = camp.id;
  this.idCampImage = camp.image;
  this.idCampTitulo = camp.titulo;
  this.idCampTelefono = camp.telefono;
  this.idCampContacto = camp.contacto;
  this.idCampEmail = camp.email;
  /*this.route.paramMap.subscribe((params: ParamMap) =>{
    this.idAcademia = params.get(academia.id);
    console.log('ID ACADEMIA' + this.idAcademia);
  })*/
}
abrirVentana2(camps_finish){
  console.log(JSON.stringify(camps_finish.id))
  console.log(JSON.stringify(this.CampsFinish))
  this.mostrarCampFinish = true;
  Swal.fire({
    position: 'top-end',
    html: '<div><img style="width:100%" src='+camps_finish.image+'><hr><h2 class="tituloAcademia">'+camps_finish.titulo+'</h2><hr><h3 class="infoContactoAcademia">Fecha de realización</h3><p class="infoAcademiaContactoJson"> '+camps_finish.fecha+'</p><hr></div>',
    showConfirmButton: false,
    showCancelButton: false,
    cancelButtonText: 'IR A LA PÁGINA',
    cancelButtonColor: '#008835',
  })
  this.idCampFinish = camps_finish.id;
  this.idCampFinishFecha = camps_finish.fecha;
  this.idCampFinishTitulo = camps_finish.titulo;
  /*this.route.paramMap.subscribe((params: ParamMap) =>{
    this.idAcademia = params.get(academia.id);
    console.log('ID ACADEMIA' + this.idAcademia);
  })*/
}
onSubmit() {
  this.submitted = true;

  if (this.saveFormulario.value.nombre == '' &&
    this.saveFormulario.value.apellidos == '' &&
    this.saveFormulario.value.email == '' &&
    this.saveFormulario.value.telefono == '' &&
    this.saveFormulario.value.empresa == '' &&
    this.saveFormulario.value.detalles == '') {

      Swal.fire({
        html: '<h1 style="font-family: BetisRegular;">Debes rellenar todos los campos para poder enviar tus datos!</h1>',
        icon: 'error',
        iconColor: '#008835',
        showCloseButton: true,
        showConfirmButton: false,
      });

    
  }else{
  this.authService.saveFormulario(
    this.saveFormulario.value.nombre,
    this.saveFormulario.value.apellidos,
    this.saveFormulario.value.email,
    this.saveFormulario.value.telefono,
    this.saveFormulario.value.empresa,
    this.saveFormulario.value.detalles
    ).subscribe({
      next: data => {
        console.log(data);
        Swal.fire({
          html: '<h1>Datos enviados correctamente!</h1>',
          icon: 'success',
          iconColor: '#008835',
          showCloseButton: true,
          showConfirmButton: false,
        });
        /*setTimeout(function(){
          window.location.replace('admin/create-evento');
        }, 700);*/
      },
      error: err => {
        console.log(err.error.message);
        Swal.fire({
          text: err.error.message,
          icon: 'error',
          iconColor: '#008835',
          showCloseButton: true,
          showConfirmButton: false,
        });
      }
    })
  }
}
}